import React, { ReactElement } from "react";

import colors from "@styles/variables/bestparaquatlawyersVariables.module.scss";
import Fatigue from "./icons/Fatigue";
import Tremors from "./icons/Tremors";
import Slowness from "./icons/Slowness";
import Speech from "./icons/Speech";
import Writing from "./icons/Writing";
import Smell from "./icons/Smell";
import FeatureList from "@components/shared/featureList";

const sideEffectList = [
    {
        title: "Speech Changes",
        icon: <Speech />,
    },
    {
        title: "Tremors",
        icon: <Tremors />,
    },
    {
        title: "Fatigue/ Apathy",
        icon: <Fatigue />,
    },
    {
        title: "Diminished Smell",
        icon: <Smell />,
    },
    {
        title: "Slowness of Movement",
        icon: <Slowness />,
    },
    {
        title: "Writing Changes",
        icon: <Writing />,
    },
];

export default function SideEffects(): ReactElement {
    return (
        <FeatureList
            featureList={sideEffectList}
            mainTitle="What Are Early Signs and Symptoms of Parkinson's Disease?"
            mainDescription={
                <span className="flex flex-col">
                    <span>
                        Parkinson's is a progressively debilitating neurological
                        condition that develops as the dopamine-producing
                        neurons in the body begin to deteriorate. Often
                        undetected at early stages, Parkinson's disease
                        consistently affects the whole body in very significant
                        ways.
                    </span>
                    <span className="mt-4">
                        While treatment can help through the developing stages,
                        Parkinson's disease is a progressive illness, where
                        patients' conditions inevitably deteriorate over time.
                        During the final phase of Parkinson's, victims are often
                        wheelchair-constrained and eventually bedridden, needing
                        constant care. Symptoms of Parkinson's disease:
                    </span>
                </span>
            }
            classes={{
                mainSectionClasses:
                    "mx-auto lg:max-w-7xl flex justify-center items-start flex-col px-8 mb-10",
                mainTitleClasses: "text-2xl font-bold text-left mb-8 leading-9",
                mainDescriptionClasses:
                    "text-sm text-left md:text-lg leading-9",
                innerSectionClasses:
                    "flex flex-wrap justify-center mt-12 gap-16",
                oneBoxClasses:
                    "flex flex-col items-center justify-center w-52 md:w-64 mx-10 text-center",
                titleClasses: "mt-2 text-sm md:text-lg ",
                iconClasses: " ",
            }}
            colors={{
                mainDescriptionColor: colors.grey,
            }}
        />
    );
}
