import React from "react";

function Slowness() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="130"
            fill="none"
            viewBox="0 0 130 130"
        >
            <circle cx="65" cy="65" r="65" fill="#EBF2E6"></circle>
            <g clipPath="url(#clip0_34_22)">
                <path
                    fill="#595754"
                    d="M60.987 42.409a41.118 41.118 0 00-.601 17.733c10.693 4.898 25.352 4.352 25.352 4.352C87.86 59.798 87.2 50.91 87.74 40l-26.753 2.409z"
                ></path>
                <path
                    fill="#356E3F"
                    d="M60.666 69.251s-2.423 10.668-.84 12.348c1.581 1.68 31.9 4.049 40.67 1.883 8.772-2.166-15.459-17.45-15.459-17.45l-24.37 3.22z"
                ></path>
                <path
                    fill="#604A91"
                    d="M59.845 73.502c-.52 3.178-.921 7.126 0 8.097.621.648 5.507 1.397 11.775 2.024L59.845 73.502zM87.5 67.713l-.46-.304-19.305.972-7.069.93s-.1.406-.24 1.074C63.79 73.057 72.54 77.51 87.5 67.713z"
                    opacity="0.31"
                ></path>
                <path
                    fill="#3B3937"
                    d="M60.166 57.591a20.821 20.821 0 00-.18 12.146A47.947 47.947 0 0088.2 68.34a32.545 32.545 0 00-.881-11.943s-15.38 3.016-27.154 1.194z"
                ></path>
                <path
                    fill="#26221C"
                    d="M94.209 72.308s-2.524-1.58-3.745 2.53l3.745-2.53z"
                ></path>
                <path
                    fill="#595754"
                    d="M29.487 42.409a40.262 40.262 0 00-2.704 14.413c8.892 7.773 28.316 5.202 28.316 5.202 0-7.145-.78-7.59 1.062-19.615H29.486z"
                ></path>
                <path
                    fill="#356E3F"
                    d="M26.123 68.239s-3.865 8.927-1.282 11.113S51.475 90.506 58.083 89.98c6.608-.527 10.353-3.38 6.008-8.097-4.346-4.717-10.994-13.462-10.994-13.462s-25.693-9.453-26.974-.182z"
                ></path>
                <path
                    fill="#604A91"
                    d="M53.537 69.049l-25.412-3.806a3.86 3.86 0 00-1.362 1.24 3.912 3.912 0 00-.64 1.736c1.321 2.409 4.726 5.465 13.417 6.984 10.252 1.801 14.338-1.235 16.02-3.523a100.22 100.22 0 01-2.023-2.631z"
                    opacity="0.31"
                ></path>
                <path
                    fill="#3B3937"
                    d="M27.144 56.295a13.96 13.96 0 00-2.59 4.969 14.052 14.052 0 00-.434 5.598 1.304 1.304 0 00.721 1.033c3.384 1.599 19.364 8.725 30.358 4.271.273-4.001.18-8.02-.28-12.004 0 0-10.473 3.806-27.775-3.867z"
                ></path>
                <path
                    fill="#26221C"
                    d="M49.652 76.943s1.442-4.352 7.11-3.218l-7.11 3.218zM52.336 80.142s1.442-4.353 7.109-3.219l-7.11 3.219z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_34_22">
                    <path
                        fill="#fff"
                        d="M0 0H80.702V50H0z"
                        transform="translate(24 40)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Slowness;
