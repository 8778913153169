import React from "react";

function Fatigue() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="130"
            fill="none"
            viewBox="0 0 130 130"
        >
            <circle cx="65" cy="65" r="65" fill="#EBF2E6"></circle>
            <g clipPath="url(#clip0_34_19)">
                <path
                    fill="#000"
                    d="M98.81 75.592h3.93a2.977 2.977 0 002.978-2.977v-6.28a2.977 2.977 0 00-2.978-2.976h-3.93a2.977 2.977 0 00-2.978 2.977v6.279a2.977 2.977 0 002.977 2.977z"
                ></path>
                <path
                    fill="#CFC8C0"
                    d="M96.48 53.931H31.406A2.405 2.405 0 0029 56.336v26.24a2.405 2.405 0 002.405 2.405H96.48a2.405 2.405 0 002.404-2.405v-26.24a2.405 2.405 0 00-2.404-2.405z"
                ></path>
                <path
                    fill="#EBF2E6"
                    d="M70.649 58.015h-12.94A2.71 2.71 0 0055 60.725v17.481a2.71 2.71 0 002.71 2.71h12.939a2.71 2.71 0 002.71-2.71v-17.48a2.71 2.71 0 00-2.71-2.71zM90.641 58.015H77.702a2.71 2.71 0 00-2.71 2.71v17.481a2.71 2.71 0 002.71 2.71h12.94a2.71 2.71 0 002.71-2.71v-17.48a2.71 2.71 0 00-2.71-2.71z"
                ></path>
                <path
                    fill="#356E3F"
                    d="M49.649 58.015h-12.94A2.71 2.71 0 0034 60.725v17.481a2.71 2.71 0 002.71 2.71h12.939a2.71 2.71 0 002.71-2.71v-17.48a2.71 2.71 0 00-2.71-2.71z"
                ></path>
            </g>
            <path
                fill="#356E3F"
                d="M63.626 27L48 49.196h11.793L56.375 63 71 41.3H60.33L63.626 27z"
            ></path>
            <defs>
                <clipPath id="clip0_34_19">
                    <path
                        fill="#fff"
                        d="M0 0H76.698V50H0z"
                        transform="translate(29 35)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Fatigue;
