import React from "react";

function Writing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="130"
            fill="none"
            viewBox="0 0 130 130"
        >
            <circle cx="65" cy="65" r="65" fill="#EBF2E6"></circle>
            <g clipPath="url(#clip0_34_20)">
                <path fill="#356E3F" d="M72.769 35H33v50.048h39.769V35z"></path>
                <path
                    fill="#fff"
                    d="M58.25 41.893c-1.742 0-2.683-.675-3.601-1.329-.918-.654-1.806-1.29-3.544-1.171-1.627.116-2.728.561-3.705.959-1.249.508-2.324.949-3.904.389-2.497-.886-5.925-1.433-7.378-.062l-.4-.423c1.641-1.55 5.194-1.045 7.972-.063 1.368.486 2.259.117 3.486-.38 1.016-.413 2.17-.884 3.884-1 1.946-.132 2.95.581 3.921 1.278.971.697 1.905 1.36 3.844 1.196 2.425-.207 3.048-.786 3.708-1.394.41-.382.834-.776 1.654-1.074 2.182-.795 5.841 1.648 5.997 1.752l-.326.482c-.035-.024-3.568-2.38-5.472-1.69-.71.26-1.073.597-1.458.955-.697.653-1.43 1.328-4.052 1.55a8.996 8.996 0 01-.626.025zM40.768 47.456c-2.148 0-4.88-1.82-5.01-1.91l.324-.482c.035.024 3.568 2.38 5.473 1.688.71-.258 1.073-.596 1.458-.953.697-.653 1.429-1.328 4.052-1.55 2.153-.184 3.21.57 4.233 1.3.93.663 1.804 1.29 3.544 1.172 1.626-.11 2.728-.561 3.703-.96 1.25-.509 2.33-.948 3.905-.39 2.497.887 5.926 1.434 7.378.063l.4.423c-1.642 1.55-5.194 1.046-7.971.062-1.368-.484-2.26-.116-3.486.38-1.018.415-2.17.884-3.885 1.001-1.945.133-2.95-.58-3.92-1.278-.972-.697-1.906-1.361-3.845-1.195-2.425.207-3.047.785-3.707 1.394-.41.381-.835.775-1.655 1.074a2.882 2.882 0 01-.99.161z"
                ></path>
                <path
                    fill="#E6BD92"
                    d="M51.095 49.03c-.452 2.323 11.353 5.19 11.353 5.19s-6.148.358-8.788 1.5c-.116.31-.445 2.724 2.72 2.082 3.165-.64 7.539-.116 7.539-.116s4.829.755 6.412.982c1.584.227 5.318-1.358 4.037-4.49-1.282-3.13-3.32-5.656-4.905-6.298-1.584-.641-4.714-1.773-7.883-1.585-3.168.189-1.002-.59-4.488.378a30.638 30.638 0 00-5.997 2.356z"
                ></path>
                <path
                    fill="#FFD1A3"
                    d="M67.43 81.14s-8.332-2.681-16-16.1c-.851-1.489-3.848-12.124-3.848-12.124s3.287-2.245 6.659 7.3c2.506.686 11.941 8.562 11.941 8.562L67.43 81.14zM67.378 47.283a3.312 3.312 0 01-3.563 3.038l-12.87-1.859c-1.823-.145-3.932-1.002-3.788-2.825.145-1.823 2.061-2.596 5.096-2.038l12.083.125a3.312 3.312 0 013.042 3.559z"
                ></path>
                <path
                    fill="#FFE6CF"
                    d="M47.624 53.057c.28.867.354 1.518.772 2.685.507-.062.55-.813.517-1.077-.15-1.187-1.364-1.84-1.289-1.608z"
                ></path>
                <path
                    fill="#FFD1A3"
                    d="M64.822 50.248c.785-.29 6.943 5.495 6.943 5.495-.653 5.88-1.782 11.154-5.577 13.035 0 0-2.333 9.898 1.248 12.361 3.58 2.463 10.671 6.235 10.671 6.235L83.84 95c7.035-1.162 12.37-4.216 14.042-11.335L89.574 74.7s-5.434-17.88-6.64-21.688c-1.206-3.809-14.4-9.248-18.59-9.295-2.99 2.62.478 6.531.478 6.531z"
                ></path>
                <path
                    fill="#636363"
                    d="M91.908 80.77l2.631-4.007a.562.562 0 00-.159-.775L58.29 52.18l-16.6-8.554-1.208 1.84 14.453 11.827 36.2 23.638a.56.56 0 00.774-.161z"
                ></path>
                <path
                    fill="#FFB43D"
                    d="M56.212 58.119l3.352-5.108-1.278-.839-3.352 5.108 1.278.839zM43.357 47.819l1.19.973 1.81-2.76-1.366-.704-1.634 2.49z"
                ></path>
                <path
                    fill="#E0E0E0"
                    d="M91.134 80.932a.56.56 0 00.774-.161l2.631-4.008a.557.557 0 00-.03-.652l-2.473 3.77a.56.56 0 01-.774.161l-36.2-23.639L40.905 44.82l-.425.647 14.453 11.827 36.2 23.638z"
                ></path>
                <path
                    fill="#A8A8A8"
                    d="M38.506 43.114l.245-.374 2.782 1.126-.888 1.351-2.139-2.103z"
                ></path>
                <path
                    fill="#E0E0E0"
                    d="M40.87 44.874l-2.139-2.104-.225.344 2.139 2.103.225-.343z"
                ></path>
                <path
                    fill="#636363"
                    d="M92.133 80.428l2.181-3.323 1.693 1.111a1.988 1.988 0 01-2.18 3.323l-1.694-1.11z"
                ></path>
                <path
                    fill="#E0E0E0"
                    d="M96.579 80.968a2.03 2.03 0 00.198-.395 1.988 1.988 0 01-2.468.233l-1.694-1.112-.482.735 1.694 1.111a1.988 1.988 0 002.752-.572z"
                ></path>
                <path
                    fill="#FFB43D"
                    d="M90.634 78.377l1.216-1.854-16.037-10.526-1.216 1.853 16.037 10.527z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_34_20">
                    <path
                        fill="#fff"
                        d="M0 0H64.877V60H0z"
                        transform="translate(33 35)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Writing;
