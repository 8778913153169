import React from "react";

function Speech() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="130"
            fill="none"
            viewBox="0 0 130 130"
        >
            <circle cx="65" cy="65" r="65" fill="#EBF2E6"></circle>
            <g clipPath="url(#clip0_34_23)">
                <path
                    fill="#356E3F"
                    d="M56.417 35C42.533 35 31.25 45.486 31.25 58.593a22.334 22.334 0 003.376 11.786 33.658 33.658 0 01-6.648 3.125 17.449 17.449 0 0010.486 1.594 25.9 25.9 0 0017.973 7.11c13.904 0 25.166-10.487 25.166-23.615C81.604 45.465 70.32 35 56.417 35z"
                ></path>
                <path
                    fill="#fff"
                    d="M48.594 59.348a1.95 1.95 0 11-3.9 0 1.95 1.95 0 013.9 0zM58.535 59.348a1.95 1.95 0 11-3.9 0 1.95 1.95 0 013.9 0zM68.475 59.348a1.95 1.95 0 11-3.9 0 1.95 1.95 0 013.9 0z"
                ></path>
                <path
                    fill="#fff"
                    stroke="#356E3F"
                    strokeWidth="0.5"
                    d="M97.138 85.901a26.406 26.406 0 004.712 2.292 13.632 13.632 0 01-7.54.96l-.122-.019-.09.085a20.197 20.197 0 01-14.024 5.552h-.001c-10.824 0-19.59-8.291-19.59-18.373s8.766-18.373 19.59-18.373c10.845 0 19.61 8.291 19.61 18.373a17.408 17.408 0 01-2.626 9.159l-.132.213.213.131z"
                ></path>
                <path
                    fill="#356E3F"
                    d="M86.26 76.985a1.531 1.531 0 103.061 0 1.531 1.531 0 00-3.062 0zM78.416 76.985a1.531 1.531 0 103.062 0 1.531 1.531 0 00-3.062 0zM70.573 76.985a1.531 1.531 0 103.062 0 1.531 1.531 0 00-3.063 0z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_34_23">
                    <path
                        fill="#fff"
                        d="M0 0H74.491V60H0z"
                        transform="translate(28 35)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Speech;
