import React from "react";

function Smell() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="130"
            fill="none"
            viewBox="0 0 130 130"
        >
            <circle cx="65" cy="65" r="65" fill="#EBF2E6"></circle>
            <g clipPath="url(#clip0_34_24)">
                <path
                    fill="#FDAA9F"
                    d="M70.744 83.737s26.306-.57 3.322-46.902a3.544 3.544 0 00-6.328.395l-13.81 31.068s-20.073 18.429 16.816 15.44z"
                ></path>
                <path
                    fill="#26221C"
                    d="M55.463 80.4a1.677 1.677 0 01.538-1.851 4.603 4.603 0 011.724-1.029c1.204-.4 2.465-.603 3.733-.6a18.776 18.776 0 017.23 1.376 31.974 31.974 0 01-3.655.19H61.49a24.498 24.498 0 00-3.449.205c-1.107.221-2.388.411-2.578 1.709z"
                ></path>
                <path
                    fill="#FDAA9F"
                    d="M60.272 65.894s-16.91-2.389-11.643 13.256l11.643-13.256z"
                ></path>
                <path
                    fill="#26221C"
                    d="M60.272 65.894c-1.861.089-3.71.354-5.52.79a9.728 9.728 0 00-4.572 2.358 3.401 3.401 0 00-.776.949c-.247.339-.444.712-.585 1.107-.126.395-.285.791-.395 1.218-.111.427-.095.87-.158 1.297a5.578 5.578 0 000 1.36v1.377c.142.917.284 1.835.363 2.8a36.142 36.142 0 01-1.123-2.658 16.035 16.035 0 01-.474-2.895 7.325 7.325 0 012.024-5.678 9.492 9.492 0 015.458-2.452 14.365 14.365 0 015.758.427z"
                ></path>
                <path
                    fill="#FDCAB6"
                    d="M77.925 72.095c-2.436.775-5.773-2.832-7.45-8.068-1.677-5.236-1.06-10.108 1.376-10.899 2.436-.79 5.774 2.832 7.45 8.068 1.677 5.236 1.06 10.108-1.376 10.899z"
                ></path>
                <path
                    fill="#356E3F"
                    d="M62.66 83.706a.854.854 0 100-1.709.854.854 0 000 1.709zM67.707 88.34a1.519 1.519 0 100-3.037 1.519 1.519 0 000 3.037zM75.885 92.785a2.167 2.167 0 11-2.152-2.15 2.151 2.151 0 012.152 2.15z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_34_24">
                    <path
                        fill="#fff"
                        d="M0 0H36.478V60H0z"
                        transform="translate(47 35)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Smell;
